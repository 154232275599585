.pink-bg {
    background-color: pink;
}

.homepage-cards {
    cursor: pointer;
    height: 25vh;
    border-radius: 0.5em;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
}

.homepage-cards:hover {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.20);
}

.card-container {
    /* background-color: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    padding: 1%;
}

.homepage-logos {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .hrSystemLogo {
    transform: scale(0.90);
  }

  .taskManagerLogo {
    transform: scale(1);
  }

@media screen and (max-width: 912px) {
  .hrSystemLogo {
    transform: scale(0.6);
  }
}

@media screen and (max-width: 540px) {
  .homepage-logos {
    transform: scale(0.5);
  }
}

@media screen and (max-width: 430px) {
  .homepage-logos {
    transform: scale(0.7);
  }
  .hrSystemLogo {
    transform: scale(0.5);
  }
}